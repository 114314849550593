import { Tab } from "@headlessui/react";
import { FirebaseError } from "firebase/app";
import {
  getMultiFactorResolver,
  MultiFactorInfo,
  MultiFactorResolver,
  MultiFactorSession,
} from "firebase/auth";
import { Link, navigate } from "gatsby";
import * as React from "react";
import { Id, toast, TypeOptions } from "react-toastify";
import CheckField from "../../components/fields/CheckField";
import VerifyPhoneNumberForm from "../../components/forms/VerifyPhoneNumberForm";
import Shell from "../../components/shell/Shell";
import { useFirebaseService } from "../../core/contexts/firebase";
import { useRedirectUrl } from "../../core/hooks/useRedirectUrl";
import { useShowReCaptcha } from "../../core/hooks/useShowRecaptcha";
import { FirebaseAuthErrorCode } from "../../core/services/firebase";
import appConfig from "../../core/utils/config";

const VerifyPage = ({ location }: any) => {
  const secondsToTime = (secs: number) => {
    const hours = Math.floor(secs / (60 * 60));

    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    const obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };

    return `${obj.m < 10 ? "0" + obj.m : obj.m}:${
      obj.s < 10 ? "0" + obj.s : obj.s
    }`;
  };

  const [recaptchaState] = useShowReCaptcha();
  const firebaseService = useFirebaseService();
  const [verificationId, setVerificationId] = React.useState("");
  const [verificationCode, setVerificationCode] = React.useState("");
  const [mfaDisplayName, setMfaDisplayName] = React.useState("");
  const [type, setType] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [reason, setReason] = React.useState("");
  const [counter, setCounter] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [canRedirect, setCanRedirect] = React.useState(false);
  const mfaResolverRef = React.useRef<MultiFactorResolver | null>(null);
  const [selectedHint, setSelectedHint] =
    React.useState<MultiFactorInfo | null>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const [redirectUrl, redirectSearch] = useRedirectUrl();
  const getRedirectUrl = () => {
    let url = "/dashboard";
    if (redirectUrl) {
      url = redirectUrl;
      if (redirectSearch) {
        url = `${url}${redirectSearch}`;
      }
    }
    return url;
  };

  // Setup MFA
  const { enableTwoFactorAuth } = appConfig;
  const mfsRef = React.useRef<MultiFactorSession | null>(null);
  React.useEffect(() => {
    setIsLoading(true);
    if (selectedHint && firebaseService) {
      if (mfsRef.current == null) {
        updateMultiFactorSession().catch(() => setIsLoading(false));
      }
    }
  }, [selectedHint, firebaseService, mfsRef.current]);

  const updateMultiFactorSession = async (): Promise<void> => {
    try {
      if (firebaseService) {
        const mfs = await firebaseService.doGetMultiFactorSession();
        mfsRef.current = mfs;
      }
    } catch (error) {
      console.error("unable to update multifactor session: " + error);
    }
  };

  // we need to keep a reference of the toastId to be able to update it
  const toastId = React.useRef<Id | null>(null);

  // Third Attempts
  React.useEffect(() => {
    if (canRedirect && redirectUrl && counter === 0) {
      navigate(redirectUrl);
    }
    const timer =
      counter > 0
        ? setInterval(() => setCounter(counter - 1), 1000)
        : undefined;
    return () => clearInterval(timer);
  }, [counter]);

  const getVerificationType = (type?: string | null) => {
    if (type === "magic_link") {
      return "Magic Link";
    } else if (type === "init_email" || type === "verified_email") {
      return "Email Verification";
    } else {
      return "No Type Available";
    }
  };

  const onStartTimerClick = () => {
    setCounter(5);
  };

  const initVerification = async () => {
    if (location.search && firebaseService) {
      const params = new URLSearchParams(location.search);
      const type = params.get("type");
      setType(getVerificationType(type));

      try {
        if (type === "magic_link") {
          setStatus("Verifying the magic link");
          const email = params.get("email");
          if (email) {
            const credential = await firebaseService?.doConfirmSignInLink(
              email,
              location.href
            );
            if (credential) {
              setStatus("Verification of magic link completed successfully");
              setCanRedirect(true);
              onStartTimerClick();
            } else {
              setStatus("Verification of magic link failed");
            }
          }
        }
      } catch (error) {
        let message =
          "Something went wrong while verifying your magic link, please try again later";
        if (error instanceof FirebaseError) {
          if (error.code === "auth/multi-factor-auth-required") {
            if (!enableTwoFactorAuth) {
              message =
                "Unable to complete verification. Account requires two-factor authentication in a disabled environment. Please contact support.";
            } else {
              // The user is a multi-factor user. Second factor challenge is required.
              mfaResolverRef.current = getMultiFactorResolver(
                firebaseService.auth,
                error as any
              );
              mfsRef.current = mfaResolverRef.current.session;
              message =
                "Account was authenticated successfully. Redirecting you to multi-factor authentication.";
              makeToast(message, toast.TYPE.SUCCESS);
              toastId.current = null;
              setSelectedHint(mfaResolverRef.current.hints[0]);
              setSelectedIndex(selectedIndex + 1);
              return;
            }
          } else if (error.code === FirebaseAuthErrorCode.WrongPassword) {
            message =
              "Unable to verify. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserNotFound) {
            message =
              "Unable to verify. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserDisabled) {
            message =
              "Unable to verify. Account disabled. Please contact support.";
          } else if (error.code === FirebaseAuthErrorCode.InvalidOOBCode) {
            message =
              "Unable to verify. Invalid link. Plese create a new magic link.";
          }
          makeToast(message, toast.TYPE.ERROR, false);
          toastId.current = null;
          setReason(message);
        }
      }

      try {
        if (type === "init_email") {
          const email = params.get("email");
          setStatus(
            `Sending verification instructions to your email address (${email})`
          );
          if (email) {
            await firebaseService?.doSendEmailVerification(getRedirectUrl());
            setStatus(
              "Email address verification instructions sent successfully. Please check your inbox and follow the instructions."
            );
          }
        }
      } catch (error) {
        let message =
          "Something went wrong while sending verification instructions to your email address, please try again later";
        if (error instanceof FirebaseError) {
          setReason(message);
        }
      }

      if (type === "verified_email") {
        setStatus("Verification of email address completed successfully");
        setCanRedirect(true);
        onStartTimerClick();
      }
    }
  };

  if (type === "") {
    initVerification();
  }

  const makeToast = (
    message: string,
    type?: TypeOptions,
    autoClose?: number | false
  ) => {
    // check if we already displayed a toast
    if (toastId.current === null) {
      toastId.current = toast(message, {
        type,
        autoClose,
      });
    } else {
      toast.update(toastId.current, {
        render: message,
        type,
        autoClose,
      });
    }
  };

  const getCurrentDescription = () => {
    switch (selectedIndex) {
      case 0:
        return (
          <p>
            This page performs all verifications automatically. Please wait.
          </p>
        );
      case 1:
        return (
          <p>
            Verify access to your account and the dashboard. Before you
            continue, please choose one of the available authentication factors
            below that you would like to verify yourself by.
          </p>
        );
      case 2:
        return (
          <p>
            Verify access to your account and the dashboard. Verification code
            sent successfully. Please enter the 6-digit code sent to your phone
            number.
          </p>
        );
      default:
        return "Verify access";
    }
  };

  const onVerifyCode = async (isValid: boolean) => {
    if (isValid && firebaseService && mfaResolverRef.current) {
      setIsLoading(true);
      try {
        let message = "Verifying your code.";
        makeToast(message, toast.TYPE.INFO);
        const assertion = await firebaseService.doVerifyPhoneNumberCode(
          verificationId,
          verificationCode
        );
        await mfaResolverRef.current.resolveSignIn(assertion);
        message = "Code verified successfully.";
        makeToast(message, toast.TYPE.SUCCESS);
        setIsLoading(false);
        toastId.current = null;
        navigate(getRedirectUrl());
      } catch (error) {
        setIsLoading(false);
        let message =
          "Something went wrong while verifying your code, please try again later";
        if (error instanceof FirebaseError) {
          if (error.code === FirebaseAuthErrorCode.WrongPassword) {
            message =
              "Unable to verify code. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserNotFound) {
            message =
              "Unable to verify code. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserDisabled) {
            message =
              "Unable to verify code. Account disabled. Please contact support.";
          } else if (error.code === "auth/invalid-phone-number") {
            message =
              "Unable to verify code. Account phone number is invalid. Please contact support.";
          }
        }
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };

  const onResendCode = async () => {
    if (mfaResolverRef.current && recaptchaState.ref) {
      setIsLoading(true);
      try {
        const message = "Sending verification code to your phone number";
        makeToast(message, toast.TYPE.INFO);
        setVerificationId("");
        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          multiFactorHint: selectedHint!,
          session: mfaResolverRef.current.session,
        };
        const verificationId =
          await firebaseService?.doSendPhoneNumberVerification(
            phoneInfoOptions,
            recaptchaState.ref
          );
        if (verificationId) {
          setVerificationId(verificationId);
          if (selectedIndex === 1) {
            setSelectedIndex(selectedIndex + 1);
          }
        } else {
          const message = "Sending verification code to phone number failed";
          makeToast(message, toast.TYPE.ERROR, false);
          toastId.current = null;
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        let message =
          "Something went wrong while sending verification code to your phone number, please try again later";
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };

  return (
    <Shell>
      <div className="overflow-hidden">
        <div className="relative mx-auto max-w-7xl py-32 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
            <div>
              <p className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
                Verification Page
              </p>
              <p className="mt-5 max-w-5xl text-xl text-gray-500">
                {getCurrentDescription()}
              </p>

              <Tab.Group selectedIndex={selectedIndex}>
                <Tab.List className="hidden">
                  <Tab>Tab 1</Tab>
                  <Tab>Tab 2</Tab>
                  <Tab>Tab 3</Tab>
                </Tab.List>
                <Tab.Panels>
                  <Tab.Panel tabIndex={0}>
                    <div className="rounded-md p-4 ring-indigo-800">
                      <div className="mt-8 flex gap-4">
                        <p className="w-48 flex-shrink">Verification Type:</p>
                        <p>-</p>
                        <p className="flex-1 text-gray-500">{type}</p>
                      </div>
                      <div className="mt-8 flex gap-4">
                        <p className="w-48 flex-shrink">Verification Status:</p>
                        <p>-</p>
                        <p className="flex-1 text-gray-500">{status}</p>
                      </div>
                      {reason && (
                        <div className="mt-8 flex gap-4">
                          <p className="w-48 flex-shrink">
                            Verification Reason:
                          </p>
                          <p>-</p>
                          <p className="flex-1 text-gray-500">{reason}</p>
                        </div>
                      )}
                    </div>
                  </Tab.Panel>
                  <Tab.Panel tabIndex={1}>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (selectedHint !== null) {
                          onResendCode();
                        }
                      }}
                      className="mt-8 space-y-6"
                    >
                      {mfaResolverRef.current?.hints.map((hint) => {
                        const phoneNumber = (hint as any).phoneNumber;
                        const displayName = hint.displayName;
                        const sPhoneNumber = (selectedHint as any)?.phoneNumber;
                        const sDisplayName = selectedHint?.displayName;
                        const isSelected =
                          phoneNumber === sPhoneNumber &&
                          displayName === sDisplayName;
                        return (
                          <CheckField
                            key={hint.enrollmentTime}
                            id={hint.enrollmentTime}
                            checked={isSelected}
                            description={
                              displayName
                                ? `${displayName} - ${phoneNumber}`
                                : phoneNumber
                            }
                            onChange={(checked) => setSelectedHint(hint)}
                          />
                        );
                      })}
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Send Code
                      </button>
                    </form>
                  </Tab.Panel>
                  <Tab.Panel tabIndex={2}>
                    <div className="mt-8">
                      {" "}
                      <VerifyPhoneNumberForm
                        label="Verify Code"
                        mfaDisplayName={mfaDisplayName}
                        onMFADisplayNameChange={setMfaDisplayName}
                        onCodeChange={setVerificationCode}
                        onButtonClick={onVerifyCode}
                        onResendCodeClick={onResendCode}
                      />
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
          <div className="mt-12">
            {counter > 0 ? (
              <p>
                We will now redirect you in{" "}
                <span className="text-indigo-500">
                  {secondsToTime(counter)}
                </span>
              </p>
            ) : (
              <p></p>
            )}
          </div>
          {canRedirect && redirectUrl && (
            <div className="mt-24">
              <p>
                It's been a minute and you're still waiting? Manually{" "}
                <Link
                  className="nline-flex items-center text-base font-medium leading-4 text-indigo-600 hover:text-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  to={getRedirectUrl()}
                  replace
                >
                  redirect to the next page
                </Link>
                .
              </p>
            </div>
          )}
        </div>
      </div>
    </Shell>
  );
};

export default VerifyPage;
